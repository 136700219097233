<template>
    <div>
        <b-row>
            <!-- After Submit Button text -->
            <b-col md="12">
                <b-form-group label="After Submit Button text">
                    <b-form-input type="text" v-model="prop_siteSettingArray.submitButtonText" name="submitButtonText" />
                </b-form-group>
            </b-col>

            <!-- After Submit to Redirect URL -->
            <b-col md="12">
                <b-form-group label="After Submit to Redirect URL">
                    <b-form-input type="text" v-model="prop_siteSettingArray.submitToRedirectURL" name="submitToRedirectURL" />
                </b-form-group>
            </b-col>

            <!-- Open URL in place -->
            <b-col md="12">
                <b-form-group label="Open URL in place">
                    <Select2 v-model="prop_siteSettingArray.openUrlNewWindow" :options="this.openURL" name="openUrlNewWindow" />
                </b-form-group>
            </b-col>

            <!-- Header sub detail -->
            <b-col md="12">
                <b-form-group label="Header sub detail">
                    <b-form-textarea name="splitScreenSubTitle" placeholder="Enter note" v-model="prop_siteSettingArray.splitScreenSubTitle" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BInputGroup, BFormTextarea } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BInputGroup, BFormTextarea
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        setup() {
            const { textModelHtmlPlacement, openURL } = captureOptions();
            return {
                textModelHtmlPlacement,
                openURL
            }
        },
    }
</script>